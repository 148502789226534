<template>
	<div>
		<!--		header base-->
		<app-header :config="config"
					v-if="config.nav_active === 1 && headerTemplate === 'base'" class="tmppgt_header"></app-header>

		<div class="tmppgt_main">
			<!-- contact form -->
			<div class="tmppgt_img">
				<img :src="config.bg_mobile" class="img-fluid d-lg-none d-md-block">
				<div class="container-top container">
					<div class="row">
						<div class="col-12 tmppgt_content" v-if="config.single_column_html !== null"
							 v-html="config.single_column_html"></div>
					</div>
				</div>
			</div>
			<contact-form :config="config" :form-data="formData" :setup="setup" :json-config="jsonConfig"
						  v-observe-visibility="changeVisible" class="tmppgt_form"></contact-form>
		</div>
		<!--        address -->
		<div class="container-fluid container-bottom mt-3" style="margin-bottom: 2rem; font-size:.9rem">
			<div class="tmppgt_container">
				<div class="row">
					<div class="col-12 mt-3 mt-md-3 disclaimer mb-3 mb-md-3" v-if="setup.disclaimer_zone === 'bottom'">
						<p id="privacy2" v-html="setPrivacyText()"></p>
						<p id="contract2">
							<strong v-html="campaignContract"></strong>
						</p>
						<p id="disclaimer2" v-html="setup.campaign_disclaimer"></p>
					</div>
				</div>
			</div>
		</div>

		<!--       whatsapp -->
		<whatsapp v-if="whatsappActive" id="whatsapp-float" :config="config"></whatsapp>

		<!--footer -->
		<app-footer :config="config" :setup="setup" :seller-id="sellerId"
					:force-form-marketing-texts="formForceMarketingTexts"
					v-if="footerTemplate === 'base'"></app-footer>

		<!--footer rrg-->
		<app-footer-rrg :config="config" :setup="setup" :seller-id="sellerId"
						v-if="footerTemplate === 'rrg'"></app-footer-rrg>

		<debugger :config="config"></debugger>
	</div>
</template>

<script>
import '@/assets/styles/scss/tmppgt.scss';
import setFontMixin        from '@/common/set-font-mixin';
import setStylesPeuMixin   from '@/common/set-styles-peu-mixin';
import setWhatsappPosMixin from '@/common/set-whatsapp-pos-mixin';
import Disclaimer          from '@/components/shared/Disclaimer';
import WhatsappFloat       from '@/components/shared/WhatsappFloat';
import router              from '@/router';
import VueGtm              from '@gtm-support/vue2-gtm';
import * as moment         from 'moment';
import Vue                 from 'vue';
import {mapState}          from 'vuex';

moment.locale('pt');

const Header              = () => import(/* webpackChunkName: "index" */ '@/components/Header');
const Footer              = () => import(/* webpackChunkName: "index" */ '@/components/Footer');
const TabbedStaticContent = () => import(/* webpackChunkName: "index" */ '@/components/shared/TabbedStaticContent');

export default {
	name       : 'Base',
	components : {
		Debugger                : () => import(/* webpackChunkName: "index" */ '@/components/Debugger'),
		'app-header'            : Header,
		'app-footer'            : Footer,
		'disclaimer'            : Disclaimer,
		'whatsapp'              : WhatsappFloat,
		'tabbed-static-content' : TabbedStaticContent,
		AddressV1               : () => import(/* webpackChunkName: "index" */ '@/components/shared/AddressV1'),
		AddressV2               : () => import(/* webpackChunkName: "index" */ '@/components/shared/AddressV2'),
		ContactForm             : () => import(/* webpackChunkName: "index" */ '@/components/shared/ContactForm'),
	},
	mixins     : [setStylesPeuMixin, setWhatsappPosMixin, setFontMixin],
	props      : {
		response : {
			type     : Object,
			required : true
		}
	},
	data() {
		return {
			// base settings
			config     : Object,
			setup      : Object,
			cssData    : Object,
			jsonConfig : Object,
			formData   : Object,

			gtmId                   : String,
			cookiesText             : String,
			formForceMarketingTexts : Boolean,
			whatsappActive          : Boolean,

			headerTemplate : 'base',
			footerTemplate : 'base',
			addressStyle   : 'null',

			sellerStands     : Object,
			carouselData     : 0,
			sellerId         : String,
			logo             : String,
			hasLogo          : Boolean,
			logoAlt          : String,
			addresses        : String,
			headerConfig     : Object,
			logo_left        : String,
			logos_right      : String,
			requestAddresses : Object,
			campaignContract : String,
			isStockPage      : false,
			isVisible        : false,
			listingId        : String,

			//flip-clock
			labels : {
				days    : 'dias',
				hours   : 'horas',
				minutes : 'minutos',
				seconds : 'segundos'
			},
		};
	},
	computed : mapState(['fatForm', 'formPlacement']),
	watch    : {
		fatForm(newValue, oldValue) {
			this.formData.fat_form = newValue;
		},
		carouselData() {
			this.$refs.carousel.slideTo(this.carouselData);
		},
		formPlacement(newValue, oldValue) {
			this.formData.form_placement = newValue;
		}
	},
	destroyed() {
		window.removeEventListener('scroll', this.handleScroll);
	},
	created() {
		window.addEventListener('scroll', this.handleScroll);

		this.isStockPage = window.location.href.indexOf('stock') > -1;
		this.setData(this.response);
		this.setInitStyles();
		this.setFavIco(this.config.favicon !== null ? this.config.favicon : '');
		this.setTitle(this.config.meta_title !== null ? this.config.meta_title : '');
		this.setFont();
		this.setCountDownFont();
		this.setMetas(this.config);

		this.$eventBus.$on('change-version', (data) => {
			this.form.fields.version = data;
		});

		this.$eventBus.$on('set-cookies-gtm', (data) => {
			if (data) {
				this.setGtm();
				let sessionCookie = {
					accepted : true,
					domain   : window.location.hostname
				};
				this.$cookies.set(`${this.sellerId}-autoleads-cookie`, sessionCookie, '7d', '', '');
			}
		});

		this.setPos();
	},
	methods : {
		triggerCall() {
			if (this.$cookies.get(`${this.sellerId}-autoleads-cookie`) === null && !this.setup.force_cookie_consent) {
				this.setGtm();
			}
			let vm = this;
			setTimeout(() => {
				vm.$gtm.trackEvent({
					event          : 'Phone_call', // Event type [default = 'interaction'] (Optional)
					category       : 'Phone_call',
					action         : 'Phone_call',
					label          : 'Phone_call',
					eventID        : vm.$store.state.alid,
					value          : 0,
					noninteraction : false // Optional
				}, 500);
				window.alp.trackEvent({ 'event' : 'Phone_call' });
			});
		},
		changeVisible(isVisible, entry) {
			if (!isVisible && document.body.clientWidth <= 768) {
				if (document.getElementsByClassName('sticky-button').length > 0) {
					document.getElementsByClassName('sticky-button')[0].setAttribute('style', 'display:block !important;');
				}
			}
			if (isVisible && document.body.clientWidth <= 768) {
				if (document.getElementsByClassName('sticky-button').length > 0) {
					document.getElementsByClassName('sticky-button')[0].setAttribute('style', 'display:none !important;');
				}
			}
		},
		visibilityChanged(isVisible, entry) {
			this.isVisible = isVisible;
		},
		setCampaignContractText() {
			if (this.config.campaign_contract !== null) {
				return this.config.campaign_contract;
			}

			return '';
		},
		setData(response) {
			console.log(response);
			// Page Data
			this.config     = response;
			this.setup      = response.setup;
			this.cssData    = response.css !== null ? response.css : {};
			this.formData   = response.form !== null ? response.form : {};
			this.jsonConfig = response.json !== null && response.json.json_config !== null ? response.json.json_config : {};

			//trackers
			this.gtmId    = this.setup.gtm_id !== null ? this.setup.gtm_id : 'GTM-XXXXX';
			this.sellerId = this.setup.seller_id;

			//whatsapp
			this.whatsappActive          = response.whatsapp_active === 1;
			//form
			this.formForceMarketingTexts = this.setup.form_force_marketing_texts === 1;
			//modules
			this.headerTemplate          = this.setup.header_template;
			this.footerTemplate          = this.setup.footer_template;
			this.addressStyle            = this.config.address_style === null ? 'null' : this.config.address_style;

			// this.hasLogo                       = this.config.body.addressv1.logos.src !== '' && this.config.body.addressv1.logos.src !== undefined;
			// this.logo                          = this.config.body.addressv1.logos.src;
			// this.logoAlt                       = this.config.body.addressv1.logos.alt;
			// this.addresses                     = this.config.body.addressv1.addresses;

			// this.form.fields.version  = this.config.form.extra_fields.version && this.config.form.fields_settings.version.select_values.length < 2 ? this.config.form.fields_settings.version.select_values[0].id : '';
			// this.form.fields.location = this.config.form.extra_fields.location && this.config.form.fields_settings.location.select_values.length < 2 ? this.config.form.fields_settings.location.select_values[0].id : '';


			if (this.$cookies.get(`${this.sellerId}-autoleads-cookie`) !== null && this.$cookies.get(`${this.sellerId}-autoleads-cookie`).accepted && this.$cookies.get(`${this.sellerId}-autoleads-cookie`).domain === window.location.hostname) {
				setTimeout(() => {
					this.setGtm();
				}, 500);
			}

			this.campaignContract = this.setCampaignContractText();
		},

		setMetas() {
			this.helpers.setMetas(this.config);
		},

		setGtm() {
			let ids;
			if (this.gtmId.indexOf(',') > -1) {
				ids = this.gtmId.split(',');
			} else {
				ids = this.gtmId;
			}
			Vue.use(VueGtm, {
				id           : ids, // Your GTM ID
				enabled      : true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
				debug        : true, // Whether or not display console logs debugs (optional)
				vueRouter    : router, // Pass the router instance to automatically sync with router (optional)
				ignoredViews : [] // If router, you can exclude some routes name (case insensitive) (optional)
			});
		},

		setFavIco(icoUrl) {
			document.getElementById('favico').setAttribute('href', icoUrl);
		},

		setTitle(title) {
			document.getElementsByTagName('title')[0].appendChild(document.createTextNode(title));
		},
		setCountDownFont() {
			if (this.jsonConfig.countdown !== undefined) {
				const s = document.createElement('link');
				s.rel   = 'stylesheet';
				s.href  = this.jsonConfig.countdown.font;
				document.head.appendChild(s);
			}
		},
		setPrivacyText() {
			return this.helpers.setPrivacyText(this.setup);
		},
	}
};
</script>
