const setStylesPeuMixin = {
	methods : {
		setInitStyles() {
			const styles   = document.getElementById('injected-styles');
			const textnode = document.createTextNode(`
body {font-family: '${this.config.font_type_name !== null ? this.config.font_type_name : 'Roboto'}', sans-serif !important;}
body {color: ${this.cssData.body_text_color} !important;}
.main {background:${this.cssData.body_bg_color} !important;}
.tmppgt_form {background:${this.cssData.form_bg_color} !important;color:${this.cssData.form_text_color} !important;height:${this.formData !== null && this.formData.form_height !== null ? this.formData.form_height : '625px'} !important;}
.tmppgt_header, nav.navbar.navbar-light.bg-light {background:${this.cssData.nav_bg_color} !important;}
.Cookie--mytheme {background: ${this.cssData.footer_bg_color}!important;}
a{color:${this.cssData.link_text_color}!important;}
a:hover{color:${this.cssData.link_text_color}!important;}
.btn.btn-primary{border-radius: ${this.cssData.buttons_border_radius !== null ? this.cssData.buttons_border_radius : '0rem'};color: ${this.cssData.button_text_color} !important;background:${this.cssData.button_bg_color}!important;border-color:${this.cssData.button_bg_color}!important;}
.btn.btn-primary:hover{background:${this.cssData.button_border_color}!important;border-color:${this.cssData.button_border_color}!important;}
.thank-you{color:${this.cssData.form_success_text_color}!important;}
.footer .Cookie--mytheme a {color: ${this.cssData.footer_link_text_color !== null ? this.cssData.footer_link_text_color : this.cssData.link_text_color} !important;}
.footer a {color:${this.cssData.footer_link_text_color !== null ? this.cssData.footer_link_text_color : this.cssData.link_text_color} !important;}
.footer {background:${this.cssData.footer_bg_color} !important;}


@media only screen and (min-width: 1024px) {
	.tmppgt_main .tmppgt_img {
		background: url(${(this.config.image_desktop_responsive === 0 ? this.config.bg_desktop : 'unset')}) center top no-repeat ${this.cssData.body_bg_color} !important;
		background-size: cover !important;
	}
}

@media only screen and (max-width: 991px) {
	.tmppgt_main .tmppgt_img {
		background: url(${this.config.bg_mobile}) center top no-repeat ${this.cssData.body_bg_color} !important;
		background-size: cover !important;
	}
	.tmppgt_img{
		height:100%;
	}
}

@media only screen and (max-width: 1023px) {
	.image-top{${this.config.nav_active === 1 && this.config.responsive_image_mobile === 1 ? 'margin-top:30px!important;' : 'margin-top:0px!important;'}}
}
@media only screen and (max-width: 768px) {
    .tmppgt_form {
        height: 100% !important;
    }
}

${this.cssData.injected_css}

@media only screen and (min-width: 768px)  and (max-width: 1024px){
	${this.cssData.injected_css_ipad}
}

@media only screen and (max-width: 700px) {
	${this.cssData.injected_css_mobile}
}

${this.config.css_fast_fixes}
        `);
			styles.appendChild(textnode);
		}
	},
};
export default setStylesPeuMixin;
